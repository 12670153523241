<script setup lang="ts">
import type { Schemas } from "#shopware";

const { apiClient } = useShopwareContext();
const { pushError } = useNotifications();
const { t } = useI18n();
const { renderButton, AMAZON_PAY_PAYMENT_HANDLER } = useAmazonPay();
const { createOrder, selectedPaymentMethod } = useCheckout();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const BUTTON_CONTAINER_ID = "amazon-pay-button";

const props = defineProps<{
  disabled: boolean;
  repayOrder?: Schemas["Order"];
}>();
const { disabled, repayOrder } = toRefs(props);
const { changePaymentMethod } = useOrderPayment(repayOrder);

onMounted(async () => {
  const button = await renderButton("#" + BUTTON_CONTAINER_ID, "Checkout");
  button.onClick(async () => {
    if (disabled.value) {
      pushError(t("checkout.termsAndConditionsError"));
      return;
    }

    assert(
      selectedPaymentMethod.value?.shortName === AMAZON_PAY_PAYMENT_HANDLER,
      "amazon ist payment handler",
    );

    let order: Schemas["Order"];
    if (!repayOrder.value) {
      order = await createOrder();
    } else {
      await changePaymentMethod(selectedPaymentMethod.value?.id);
      order = repayOrder.value;
    }

    assert(order.id !== undefined, "order has id");

    const orderTransactionId = order.transactions?.[0]?.id;
    assert(orderTransactionId, "order has transaction");

    const cancelUrl = formatLink(`/checkout/success/${order.id}`) as string;
    const { data } = await apiClient.invoke(
      "amazonPayPurePayment post /amazon-pay-pure-payment",
      {
        body: {
          orderTransactionId,
          returnUrl: `${window?.location?.origin}/payment/finalize-amazon-pay?orderId=${order.id}&orderTransactionId=${orderTransactionId}`,
          cancelUrl: window?.location?.origin + cancelUrl,
        },
      },
    );

    history.pushState({}, "", cancelUrl);

    button.initCheckout({
      createCheckoutSessionConfig: {
        payloadJSON: data.payload,
        signature: data.signature,
        algorithm: "AMZN-PAY-RSASSA-PSS",
      },
    });
  });

  const style = document.createElement("style");
  style.textContent = `
  .amazonpay-button-container-rows {
    grid-template-rows: 48px 6px 10px;
  }
  `;
  document.querySelector("#" + BUTTON_CONTAINER_ID)?.shadowRoot?.append(style);
});
</script>

<template>
  <div
    :id="BUTTON_CONTAINER_ID"
    class="h-17 mt-2"
    :class="{
      'saturate-40': disabled,
      'cursor-not-allowed': disabled /* FIXME not working */,
    }"
  >
    <div
      class="h-10 b-solid b-[#9c7e31] b-t-[#a88734] b-b-[#846a29] rd-[3px] b bg-gradient-linear shape-bottom from-[#f7dfa5] to-[#f0c14b]"
    ></div>
  </div>
</template>
